<template>
  <v-navigation-drawer
    :permanent="breakpoint && show"
    v-model="show"
    :width="width"
    hide-overlay
    color="primary"
    dark
    app
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-icon left large class="pb-3">mdi-car-outline</v-icon>

          <router-link
            :to="dashboardRoute"
            v-text="title"
            class="white--text text-decoration-none"
          />
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t("sidebar.welcome", { name: `${currentUser.name}` }) }}<br />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list flat dense nav class="pb-0">
      <v-list-item :to="dashboardRoute">
        <v-list-item-icon>
          <v-icon v-text="`mdi-view-dashboard`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sidebar.dashboard')" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="documentsRoute">
        <v-list-item-icon>
          <v-icon v-text="`mdi-file-document`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sidebar.invoiceReports')" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.stop="createOrderDialog = true" v-if="isUser">
        <v-list-item-icon>
          <v-icon v-text="`mdi-plus-box`" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('sidebar.newOrder')" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-container v-if="$route.meta.sidebar">
      <v-card dense>
        <component :is="$route.meta.sidebar" />
      </v-card>
    </v-container>

    <v-container>
      <v-card :loading="inboxesLoading" dense>
        <InboxList :inboxes="inboxes" />
      </v-card>
    </v-container>

    <CreateOrderDialog v-model="createOrderDialog" @created="onOrderCreate" />
  </v-navigation-drawer>
</template>

<script>
import Mainbus from "@/mainbus";
import { mapGetters } from "vuex";
import InboxService from "@/services/inbox";

import InboxList from "@/components/InboxList";
import OrderNavigation from "@/components/Order/Navigation";
import CreateOrderDialog from "@/components/Dialogs/CreateOrder";

export default {
  name: "app-sidebar",
  components: {
    InboxList,
    OrderNavigation,
    CreateOrderDialog,
  },
  data: () => ({
    show: true,
    width: 300,
    createOrderDialog: false,

    inboxes: [],
    inboxesLoading: false,
    inboxLoader: null,
  }),
  mounted() {
    this.fetchInboxes();
    this.inboxLoader = setInterval(this.fetchInboxes, 25000);
    Mainbus.$on("refreshInboxes", () => this.fetchInboxes());
  },
  destroyed() {
    if (this.inboxLoader) {
      clearInterval(this.inboxLoader);
    }
  },
  methods: {
    onOrderCreate(order) {
      this.createOrderDialog = false;
      this.fetchInboxes();
    },

    toggle() {
      this.show = !this.show;
    },
    fetchInboxes() {
      this.inboxesLoading = true;
      return InboxService.getInboxes()
        .finally(() => (this.inboxesLoading = false))
        .then((response) => (this.inboxes = response.data))
        .catch((error) => console.log(error));
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isUser"]),
    dashboardRoute() {
      return { name: "dashboard" };
    },
    documentsRoute() {
      return { name: "invoice-reports" };
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
